import axios                         from "axios";
import { isUndefined, cloneDeep, has } from "lodash";
import moment                        from "moment";
import SWorker                       from "simple-web-worker";
import checkWebWorkersSupport        from "@/includes/helpers/checkWebWorkersSupport";

export default class Statistics {

	apiUrl;
	chatId;
	from;
	to;
	cache;

	/**
	 *
	 * @type {{timeSeries: {}, total: {}}}
	 * @private
	 */
	_dataModelExample = {
		total     : {},
		timeSeries: {},
	};

	/**
	 * For base usability was taken Apex charts
	 *
	 *
	 * @type {{data: [], dates: []}}
	 * @private
	 */
	_timeSeriesModelExample = {
		data  : [],
		labels: [],
	};

	/**
	 *
	 * @param apiUrl {string}
	 * @param chatId {number}
	 * @param from {string}
	 * @param to {string}
	 */
	constructor(apiUrl, chatId = 0, from = "", to = "") {
		this.apiUrl = apiUrl;
		this.chatId = chatId;
		this.from   = from;
		this.to     = to;
		this.cache  = {};
	}

	/**
	 *
	 * @param neededMetrics {Array<string>} - Array of needed data from API
	 */
	prepareData(neededMetrics) {
		return new Promise((resolve, reject) => {
			const missingDataInCache = [];

			neededMetrics.forEach(val => {
				if(!this.cache.hasOwnProperty(val)) {
					missingDataInCache.push(val);
				}
			});

			if(missingDataInCache.length === 0) {
				resolve(this._returnNeededData(neededMetrics));
			} else {
				this.apiRequestData(missingDataInCache)
						.then(({ data }) => {
							this._processData(data);
							resolve(this._returnNeededData(neededMetrics));
						})
						.catch(err => {
							reject(err);
						});
			}

		});
	}

	/**
	 *
	 * @param neededMetrics {Array<string>} - Array of needed data from API
	 */
	prepareSyntheticData(neededMetrics) {
		return new Promise((resolve, reject) => {
			const availableDataTypes = [ "activity", "users_retention_heat_map", "users_activity" ];

			neededMetrics.map(metric => {
				if(!availableDataTypes.includes(metric)) {
					console.error(`Needed "${ metric }" metric type doesn\`t exists.`);
					reject();
				}
			});

			this._processSyntheticData(neededMetrics)
					.then(() => {
						resolve(this._returnNeededData(neededMetrics));
					})
					.catch(err => {
						console.error(err);
						reject(err);
					});
		});
	}

	/**
	 *
	 * @param metrics {Array<string>}
	 * @return {{}}
	 * @private
	 */
	_returnNeededData(metrics) {
		const obj = {};

		metrics.map(val => {
			obj[ val ] = this.cache[ val ];
		});

		return obj;
	}

	/**
	 *
	 * @param rawData {Object}
	 * @private
	 */
	_processData(rawData) {
		for(let key in rawData) {
			if(rawData.hasOwnProperty(key)) {
				const item          = rawData[ key ];
				const prepareMethod = this[ `_prepare_${ key }` ];

				let preparedData = {};

				if(prepareMethod !== undefined && typeof prepareMethod === "function") {
					preparedData = prepareMethod(this, item);
				}

				this.cache[ key ]          = preparedData;
				this.cache[ `${ key }_raw` ] = item;
			}
		}
	}

	/**
	 *
	 * @private
	 * @param neededMetrics
	 */
	_processSyntheticData(neededMetrics) {
		return new Promise((resolve, reject) => {
			const promises = [];
			neededMetrics.map(metric => {
				const prepareMethod = this[ `_prepare_synthetic_${ metric }` ];

				if(prepareMethod !== undefined && typeof prepareMethod === "function") {
					promises.push(prepareMethod(this));
					// prepareMethod(this)
					//   .then(data => {
					//     this.cache[ metric ] = data;
					//     resolve();
					//   })
					//   .catch(err => {
					//     console.error(err);
					//     reject(err);
					//   });
				}

			});

			Promise.all(promises)
						 .then((results) => {
							 neededMetrics.map((metric, index) => {
								 this.cache[ metric ] = results[ index ];
								 resolve();
							 });
						 })
						 .catch(err => {
							 reject(err);
						 });
		});
	}

	_prepare_engagement_rate_month(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	_prepare_engagement_rate_week(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	/**
	 *
	 * @return {Promise<unknown>}
	 * @private
	 */
	_prepare_synthetic_activity(self) {
		return new Promise((resolve, reject) => {
			const neededDataTypes = [ "msg_count_hour", "msg_count_day" ];

			self.prepareData(neededDataTypes)
					.then(() => {

						/**
						 *
						 * @param type {string} - users_count/msg_count
						 */
						const countActivity = (type) => {

							const activityObject = {
								hour: {
									series: {},
									labels: [],
								},
								day : {
									series: [],
									labels: [],
								},
							};

							const msg_count_hour_series = self.cache[ "msg_count_hour" ].timeSeries[ type ];
							const msg_count_day_series  = self.cache[ "msg_count_day" ].timeSeries[ type ];

							// Count hours in period
							const fillHourObject = (s, e, series) => {
								if(!isUndefined(series)) {
									const format = "YYYY-MM-DD HH:mm:ss";

									while(!s.isAfter(e)) {
										const hourFormat = s.format("HH:mm");
										const index      = series.labels.indexOf(s.format(format));
										const realValue  = series.data[ index ];
										let value        = realValue !== undefined ? realValue : 0;

										if(activityObject[ "hour" ].series[ hourFormat ] !== undefined) {
											activityObject[ "hour" ].series[ hourFormat ].data.push(value);

											let date = s.format("YYYY-MM-DD");

											if(activityObject[ "hour" ].labels.indexOf(date) === -1) {
												activityObject[ "hour" ].labels.push(date);
											}
										} else {
											activityObject[ "hour" ].series[ hourFormat ] = {
												name: hourFormat,
												data: [ value ],
											};
										}

										s.add(1, "hour");

									}

									activityObject.hour.series[ "00:00" ].data.pop();

									activityObject.hour.series = self._sortByInt(Object.values(activityObject.hour.series)).reverse();

								}
							};

							const start = moment(self.from);
							const end   = moment(self.to);

							fillHourObject(start, end, msg_count_hour_series);

							// Count days
							const fillDayObject = (s, e, series) => {
								if(!isUndefined(series)) {
									const format = "YYYY-MM-DD HH:mm:ss";

									activityObject[ "day" ].series = {
										"Monday"   : {
											name: "",
											data: [],
										},
										"Tuesday"  : {
											name: "",
											data: [],
										},
										"Wednesday": {
											name: "",
											data: [],
										},
										"Thursday" : {
											name: "",
											data: [],
										},
										"Friday"   : {
											name: "",
											data: [],
										},
										"Saturday" : {
											name: "",
											data: [],
										},
										"Sunday"   : {
											name: "",
											data: [],
										},
									};

									while(!s.isAfter(e)) {
										const date = s.clone().startOf("week").format(format);

										const hourFormat = s.format("dddd");
										const index      = series.labels.indexOf(s.format(format));
										const realValue  = series.data[ index ];
										let value        = realValue !== undefined ? realValue : 0;

										if(activityObject[ "day" ].series[ hourFormat ].name === "") {
											activityObject[ "day" ].series[ hourFormat ].name = hourFormat;
										}

										if(activityObject[ "day" ].series[ hourFormat ] !== undefined) {
											activityObject[ "day" ].series[ hourFormat ].data.push(value);
										} else {
											activityObject[ "day" ].series[ hourFormat ] = {
												name: hourFormat,
												data: [ value ],
											};
										}

										if(activityObject[ "day" ].labels.indexOf(date) === -1) {
											activityObject[ "day" ].labels.push(date);
										}

										s.add(1, "day");

									}

									activityObject.day.series = Object.values(activityObject.day.series).reverse();
									activityObject.day.series.map(item => {
										if(item.data.length < activityObject.day.labels.length) {
											for(let i = item.data.length; i < activityObject.day.labels.length; i++) {
												item.data.push(0);
											}
										} else if(item.data.length > activityObject.day.labels.length) {
											item.data.pop();
										}
									});
								}
							};

							const dayStart = moment(self.from);
							const dayEnd   = moment(self.to);

							fillDayObject(dayStart, dayEnd, msg_count_day_series);

							return activityObject;
						};

						resolve(
							{
								messages: countActivity("msg_count"),
								users   : countActivity("users_count"),
							},
						);
					})
					.catch(err => {
						console.error(err);
						reject(err);
					});
		});
	}

	/**
	 *
	 * @return {Promise<unknown>}
	 * @private
	 */
	_prepare_synthetic_users_retention_heat_map(self) {
		return new Promise((resolve, reject) => {
			const neededDataTypes = [ "users_retention" ];

			self.prepareData(neededDataTypes)
					.then(() => {
						const usersRetentionObject         = {
							series: [],
							labels: [],
						};
						const usersRetentionMessagesObject = {
							series: [],
							labels: [],
						};

						const start = moment(self.from);
						const end   = moment(self.to);

						const format             = "YYYY-MM-DD HH:mm:ss";
						const processedDataTypes = [ "users_count", "msg_count", "user_days_in_chat" ];

						const processCount = (type, curDateTick) => {
							const title  = `statistics_users_retention_${ type }_title`;
							const series = self.cache[ "users_retention" ].timeSeries[ type ];

							if(!isUndefined(series)) {
								const index     = series.labels.indexOf(curDateTick);
								const realValue = series.data[ index ];
								let value       = realValue !== undefined ? realValue : 0;

								if(usersRetentionMessagesObject.series[ title ] !== undefined) {
									usersRetentionMessagesObject.series[ title ].data.push(value);
								} else {
									usersRetentionMessagesObject.series[ title ] = {
										name: title,
										data: [ value ],
									};
								}

							}
						};

						const processUserDays = (type, curDateTick) => {
							const series      = self.cache[ "users_retention_raw" ];
							const orderLength = 31;
							let itemIndex     = null;
							const putToObj    = (index, val) => {
								const title = `statistics_users_retention_${ type }_${ index }_title`;

								if(usersRetentionObject.series[ title ] !== undefined) {
									usersRetentionObject.series[ title ].data.push(val);
								} else {
									usersRetentionObject.series[ title ] = {
										name: title,
										data: [ val ],
									};
								}
							};

							series.map((item, index) => {
								if(item.time === curDateTick) itemIndex = index;
							});

							if(itemIndex !== null) {
								series[ itemIndex ][ type ].map((val, index) => {
									if(index > 0) {
										putToObj(index, val);
									}
								});
							} else {
								for(let i = 1; i <= orderLength; i++) {
									putToObj(i, 0);
								}
							}
						};

						while(!start.isAfter(end)) {
							const curDateTick = start.format(format);
							usersRetentionObject.labels.push(curDateTick);
							usersRetentionMessagesObject.labels.push(curDateTick);

							processedDataTypes.map(type => {
								switch(type) {
									case "users_count":
										processCount(type, curDateTick);
										break;
									case "msg_count":
										processCount(type, curDateTick);
										break;
									case "user_days_in_chat":
										processUserDays(type, curDateTick);
										break;
								}

								return true;
							});

							start.add(1, "day");

						}

						usersRetentionObject.series         = Object.values(usersRetentionObject.series);
						usersRetentionMessagesObject.series = Object.values(usersRetentionMessagesObject.series);

						resolve(
							{
								messages: usersRetentionMessagesObject,
								users   : usersRetentionObject,
							},
						);
					})
					.catch(err => {
						console.error(err);
						reject(err);
					});
		});
	}

	/**
	 *
	 * @return {Promise<unknown>}
	 * @private
	 */
	_prepare_synthetic_users_activity(self) {
		return new Promise((resolve, reject) => {
			const neededDataTypes = [ "users_new_hour", "users_new_day", "msg_count_hour", "msg_count_day" ];

			self.prepareData(neededDataTypes)
					.then(() => {
						const resObject   = {};
						const hourObjTmpl = {
							series: [],
							labels: [],
						};
						const dayObjTmpl  = {
							series: [],
							labels: [],
						};
						const hoursStart  = moment().startOf("day");
						const hoursEnd    = moment().endOf("day");
						const dayStart    = moment().startOf("week");
						const dayEnd      = moment().endOf("week");

						while(!hoursStart.isAfter(hoursEnd)) {
							hourObjTmpl.series.push(null);
							hourObjTmpl.labels.push(hoursStart.format("HH"));
							hoursStart.add(1, "hour");
						}

						while(!dayStart.isAfter(dayEnd)) {
							dayObjTmpl.series.push(null);
							dayObjTmpl.labels.push(dayStart.format("ddd"));
							dayStart.add(1, "day");
						}

						const process = (rawData, target, format) => {
							const tmpDaysCountObj = {};
							if(!isUndefined(rawData)) {
								rawData.labels.map((dateTime, index) => {
									const day = moment(dateTime).format(format);
									if(tmpDaysCountObj[ day ] !== undefined) {
										tmpDaysCountObj[ day ]++;
									} else {
										tmpDaysCountObj[ day ] = 0;
									}
									target.series[ target.labels.indexOf(day) ] += rawData.data[ index ];
								});
								Object.keys(tmpDaysCountObj).map(key => {
									const labelIndex = target.labels.indexOf(key);
									if(tmpDaysCountObj[ key ] !== 0) {
										target.series[ labelIndex ] = Math.round(target.series[ labelIndex ] / tmpDaysCountObj[ key ]);
									} else {
										target.series[ labelIndex ] = 0;
									}

									return true;
								});
							}

						};

						[ "users_activity_day", "users_activity_hour", "msg_activity_day", "msg_activity_hour" ].map(key => {
							switch(key) {
								case "users_activity_day":
									resObject[ key ] = cloneDeep(dayObjTmpl);
									process(self.cache[ "msg_count_day" ].timeSeries.users_count, resObject[ key ], "ddd");
									break;
								case "msg_activity_day":
									resObject[ key ] = cloneDeep(dayObjTmpl);
									process(self.cache[ "msg_count_day" ].timeSeries.msg_count, resObject[ key ], "ddd");
									break;
								case "users_activity_hour":
									resObject[ key ] = cloneDeep(hourObjTmpl);
									process(self.cache[ "msg_count_hour" ].timeSeries.users_count, resObject[ key ], "HH");
									break;
								case "msg_activity_hour":
									resObject[ key ] = cloneDeep(hourObjTmpl);
									process(self.cache[ "msg_count_hour" ].timeSeries.msg_count, resObject[ key ], "HH");
									break;
							}

							return true;
						});

						resolve(resObject);
					})
					.catch(err => {
						console.error(err);
						reject(err);
					});
		});
	}

	/**
	 *
	 * @param metrics {Array<string>}
	 * @return {AxiosPromise}
	 */
	apiRequestData(metrics) {
		return new Promise((resolve, reject) => {
			const isWebWorkersSupported = checkWebWorkersSupport();
			const options = {
				method         : "post",
				url            : this.apiUrl,
				data           : {
					"chat_id": this.chatId, // id-чата
					"metrics": metrics, // Необходимые метрики
					"from"   : this.from, // Число "с"
					"to"     : this.to, // Число "по"
				},
				withCredentials: true,
			};

			if(isWebWorkersSupported) {
				// Disable parsing JSON
				options.transformResponse = (res) => res;
			}

			axios(options)
				.then((res) => {
					if(isWebWorkersSupported) {
						SWorker.run((json) => JSON.parse(json), [ res.data ])
									 .then(parsedStatisticsJson => {
										 resolve({ data: parsedStatisticsJson });
									 })
									 .catch(reject);

					} else {
						resolve(res);
					}
				})
				.catch(reject);
		});
	}

	/**
	 *
	 * @param array {Array}
	 * @return {Array}
	 * @private
	 */
	_sortByTime(array) {
		return array.sort(function(a, b) {
			let keyA = new Date(a.time),
					keyB = new Date(b.time);
			// Compare the 2 dates
			if(keyA < keyB) return -1;
			if(keyA > keyB) return 1;
			return 0;
		});
	}

	/**
	 *
	 * @param array {Array}
	 * @return {Array}
	 * @private
	 */
	_sortByInt(array) {
		return array.sort(function(a, b) {
			let keyA = parseInt(a.name),
					keyB = parseInt(b.name);
			// Compare the 2 dates
			if(keyA < keyB) return -1;
			if(keyA > keyB) return 1;
			return 0;
		});
	}

	/**
	 *
	 * @param self {Statistics}
	 * @param rawData {Array<Object>}
	 * @return {Object}
	 * @private
	 */
	_parseLinearData(self, rawData) {
		let preparedData = cloneDeep(self._dataModelExample);

	  let keys        = new Set();
	  let xAxisValues = [];
	  let timeToData  = {};

	  rawData.forEach(value => {

	    Object.entries(value).forEach(([ key, value ]) => {

	      if(key === "time") {
		xAxisValues.push(value);
	      } else {
		keys.add(key);
	      }

	    });

	    if(has(timeToData, value.time)) {
	      timeToData[ value.time ] = { ...timeToData[ value.time ], ...value };
	    } else {
	      timeToData[ value.time ] = value;
	    }
	  });

	  xAxisValues.sort(function(a, b) {
	    let keyA = new Date(a),
		keyB = new Date(b);
	    // Compare the 2 dates
	    if(keyA < keyB) return -1;
	    if(keyA > keyB) return 1;
	    return 0;
	  });

	  keys.forEach(value => {
	    preparedData.timeSeries[ `${ value }` ]        = cloneDeep(self._timeSeriesModelExample);
	    preparedData.total[ `total_${ value }` ]       = 0;
	    preparedData.total[ `last_${ value }` ]       = 0;
	    preparedData.total[ `max_${ value }` ]       = 0;
	    preparedData.total[ `min_${ value }` ]       = 0;
	    preparedData.timeSeries[ `${ value }` ].labels = xAxisValues;
	  });

	  xAxisValues.forEach(timeValue => {
	    keys.forEach(valueKey => {

	      let val = null;
	      if(has(timeToData, timeValue)) {
		let currentTimeToData = timeToData[ timeValue ];
		if(has(currentTimeToData, valueKey)) {
		  val = currentTimeToData[ valueKey ];
		}

		preparedData.timeSeries[ `${ valueKey }` ].data.push(val);

		if(val != null) {
		  preparedData.total[ `total_${ valueKey }` ] += val;
		  preparedData.total[ `last_${ valueKey }` ] = val;
		  preparedData.total[ `max_${ valueKey }` ] = Math.max(val, preparedData.total[ `max_${ valueKey }` ]);
		  preparedData.total[ `min_${ valueKey }` ] = Math.min(val, preparedData.total[ `min_${ valueKey }` ]);
		}

	      } else {
		preparedData.timeSeries[ `${ valueKey }` ].data.push(val);
	      }

	    });
	  });

	  return preparedData;
	}

	/**
	 *
	 * @param self {Statistics}
	 * @param rawData {Array<Object>}
	 * @return {Object}
	 * @private
	 */
	_prepare_users_tick(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	/**
	 *
	 * @param self {Statistics}
	 * @param rawData {Array<Object>}
	 * @return {Object}
	 * @private
	 */
	_prepare_users_new_hour(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	/**
	 *
	 * @param self {Statistics}
	 * @param rawData {Array<Object>}
	 * @return {Object}
	 * @private
	 */
	_prepare_users_new_day(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	/**
	 *
	 * @param self {Statistics}
	 * @param rawData {Array<Object>}
	 * @return {Object}
	 * @private
	 */
	_prepare_msg_count_day(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	/**
	 *
	 * @param self {Statistics}
	 * @param rawData {Array<Object>}
	 * @return {Object}
	 * @private
	 */
	_prepare_msg_count_week(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	/**
	 *
	 * @param self {Statistics}
	 * @param rawData {Array<Object>}
	 * @return {Object}
	 * @private
	 */
	_prepare_msg_count_hour(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	/**
	 *
	 * @param self {Statistics}
	 * @param rawData {Array<Object>}
	 * @return {Object}
	 * @private
	 */
	_prepare_msg_count_month(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	/**
	 *
	 * @param self {Statistics}
	 * @param rawData {Array<Object>}
	 * @return {Object}
	 * @private
	 */
	_prepare_users_retention(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	/**
	 *
	 * @param self {Statistics}
	 * @param rawData {Array<Object>}
	 * @return {Object}
	 * @private
	 */
	_prepare_messages_retention(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	/**
	 *
	 * @param self {Statistics}
	 * @param rawData {Array<Object>}
	 * @return {Object}
	 * @private
	 */
	_prepare_referral_day(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	/**
	 *
	 * @param self {Statistics}
	 * @param rawData {Array<Object>}
	 * @return {Object}
	 * @private
	 */
	_prepare_referral_hour(self, rawData) {
		return self._parseLinearData(self, rawData);
	}

	getChatsStatistics() {
		return new Promise((resolve, reject) => {
			axios({
				method         : "post",
				url            : this.apiUrl,
				data           : {},
				withCredentials: true,
			})
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
}
