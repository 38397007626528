
























import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'

import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    CenteredColumnLayout
  }
})
export default class IfChatsEmpty extends Vue {

  gotoAddGroupLink() {
    window.open('https://t.me/chatkeeperbot?startgroup&admin=restrict_members+delete_messages+invite_users+pin_messages+manage_topics', '_blank')
  }
}
